@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_PRICE_DEVIATION_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_HISTORY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ALLOWABLE_REFUND__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base.scss';

.wrapper {
  background: $dt-dark-gray-blue;
  min-height: $minimal-layout-footer-min-height-xs;

  @include media($min-sm) {
    min-height: $minimal-layout-footer-min-height-sm;
  }

  @include media($min-md) {
    min-height: $minimal-layout-footer-min-height-md;
  }
}

.container {
  margin: 0 auto;
  padding: 20px;
  max-width: $content-max-width-xl;

  @include media($min-sm) {
    padding: 20px 40px;
  }

  @include media($min-md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.link-group {
  border-top: 1px solid $medium-gray;

  @include media($min-md) {
    border: 0;
  }
}

.link-group-name {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  background: transparent;
  font-size: rem(14);
  font-weight: 700;
  color: $white;

  @include media($min-md) {
    display: none;
  }
}

.list {
  position: relative;
  margin: 0 -20px -1px;
  padding: 0 20px;
  max-height: 0px;
  overflow: hidden;
  background: $medium-gray;
  transition: max-height 0.3s;
  font-size: rem(14);
  line-height: rem(19);

  @include media($min-sm) {
    margin: 0 -40px -1px;
    padding: 0;
    font-size: rem(12);
    line-height: rem(16);
  }

  @include media($min-md) {
    display: flex;
    margin: 35px 0 30px;
    max-height: none !important;
    overflow: visible;
    background: transparent;
    font-weight: 500;
  }
}

.list-item {
  border-bottom: 1px solid $medium-light-gray;
  padding: 4px 15px;

  &:last-child {
    border: 0;
    padding-right: 0;
  }

  @include media($min-md) {
    border-bottom: 0;
    border-right: 1px solid $medium-gray;
  }
}

.link {
  display: block;
  padding: 19px 40px;
  color: $white;

  @include media($min-md) {
    padding: 0;
  }
}

.copyright {
  padding: 20px 30px 7px;
  width: 100%;
  text-align: center;
  font-size: rem(12);
  color: $dt-medium-light-gray;
  border-top: 1px solid $medium-gray;

  @include media($min-sm) {
    padding: 20px 20px 7px 0;
    text-align: left;
  }
}

@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_PRICE_DEVIATION_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_HISTORY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ALLOWABLE_REFUND__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/dt-base.scss';

.container {
  width: 100%;
  :global .add-product-btn--small-font.add-product-btn--small-font {
    width: 120px;
    font-size: rem(12);
  }
}

.item-container {
  min-height: 147px;
  max-height: 207px;
  border-bottom: 1px solid #dedede;
  overflow: hidden;
}

.tire-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

.tire-image.tire-image {
  margin: 0;
  padding-top: 17px;
  :global .product-image__image-block {
    background-position-x: calc(50% - 3px);
    width: 100px;
    height: 140px;
    margin: 0;
    @include media($min-sm) {
      background-position-x: calc(50% - 20px);
    }
  }
  :global .product-image__wheel-image-block {
    background-position-x: calc(50% - 44px);
  }
  :global .product-image__services-image-block {
    background-position-x: calc(50% - 5px);
  }
}

.product-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 17px 16px 21px 15px;
  @include media($min-sm) {
    padding: 17px 16px 21px 0;
  }
}

.name {
  font-size: rem(20);
  color: $dark-gray;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 20px;
  max-width: 200px;
}

.brand {
  color: $medium-gray;
  font-size: rem(13);
  text-transform: uppercase;
  line-height: 16px;
}

.size {
  @extend .brand;
}

.code {
  @extend .brand;
  text-transform: capitalize;
}

.price-container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  :global .price {
    font-size: rem(20);
  }

  :global .price__unit {
    font-weight: 400;
  }
}

.price-container--no-add-to-cart {
  margin-top: 6px;
}

.add-to-cart.add-to-cart {
  width: 110px;
  min-height: 40px;
  height: 40px;
  font-size: rem(13);
  padding: 12px 5px;
  min-width: unset;
  font-weight: 700;
}

.add-to-cart--right-aligned {
  margin-left: auto;
}
